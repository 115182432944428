<template>
  <span class="lottery-number" :class="{ lhc_icon: lhc_icon, lottery_icon_add: iconAdd }">
    <b v-if="iconAdd">+</b>
    <template v-else>
      <b v-show="lhc_icon">+</b>
      <img class="img-code" :class="codeClass" :src="imgSrc" v-if="imgVisible">
      <span :class="codeClass" v-else>{{ text }}</span>
      <i v-if="zhong" class="result-check" :class="zhongClass"></i>
      <i class="badge" v-show="displayBadge">{{ badge }}</i>
    </template>
  </span>
</template>

<script>
const kTypes = ['大', '小', '单', '双']
export default {
  name: 'Number',
  props: {
    lhc_icon: {
      default: false
    },
    value: {
      type: [String, Number],
      validator: function (value) {
        if (kTypes.indexOf(value) !== -1) {
          return true
        }
        const number = parseInt(value)
        if (isNaN(number)) {
          return false
        } else if (number < 0) {
          return false
        } else {
          return true
        }
      }
    },
    iconAdd: {
      type: Boolean,
      default: false
    },
    display: {
      type: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'size', 'odevity'].indexOf(value) !== -1
      }
    },
    size: {
      type: String,
      default: 'normal',
      validator: function (value) {
        return ['big', 'normal'].indexOf(value) !== -1
      }
    },
    badge: [String, Number],
    showBadge: {
      type: Boolean,
      default: false
    },
    lotteryCode: String,
    zhong: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    number() {
      return parseInt(this.value)
    },
    className() {
      var classes = []
      // 判断 code 是什么类型
      switch (this.lotteryCode) {
        case 'azxy8':
        case 'jisukl10':
        case 'gdklsf':
        case 'jisukl10168':
        case 'azxy8168':
          classes.push(`ball${this.number}`)
          break
        case 'azxy5':
        case 'azxy5168':
        case 'txffc':
        case 'jisussc':
        case 'jisussc168':
        case 'dongganwuxing':
        case 'sg5d':
          classes.push(`b-ball${this.number}`)
          break
        case 'jsk3':
        case 'donggansanbao':
        case 'speedy3':
        case 'sgquick3':
          classes.push(`dice${this.number}`)
          break
        case 'pcdd':
          classes.push(`dd${this.number}`)
          break
        case 'dongganshengxiao':
          classes.push(`zodiac${this.number}`)
          break
        case 'dongganpuke':
          classes.push(`poker${this.number}`)
          break
        case 'donggan61':
        case 'amlhc':
        case 'twlhc':
        case 'hk6':
        case 'happy61':
          classes.push(`lhc${this.number}`)
          break
        default:
          classes.push(`number${this.number}`)
          break
      }
      if (this.display === 'size') {
        classes.push('size')
      } else if (this.display === 'odevity') {
        classes.push('odevity')
      }
      if (this.size === 'big') {
        classes.push('big')
      }
      return classes
    },
    displayBadge() {
      const badge = parseInt(this.badge)
      return (badge > 0) && this.showBadge
    },
    isType() {
      return kTypes.indexOf(this.value) !== -1
    },
    codeType() {
      if (this.isType) {
        return 'type'
      }
      switch (this.lotteryCode) {
        case 'azxy8':
        case 'jisukl10':
        case 'azxy8168':
        case 'jisukl10168':
        case 'gdklsf':
          return 'ball'
        case 'azxy5':
        case 'azxy5168':
        case 'txffc':
        case 'jisussc':
        case 'jisussc168':
        case 'dongganwuxing':
        case 'sg5d':
          return 'b-ball'
        case 'jsk3':
        case 'donggansanbao':
          return 'treasure'
        case 'speedy3':
        case 'sgquick3':
          return 'dice'
        case 'pcdd':
          return 'dd'
        case 'dongganshengxiao':
          return 'zodiac'
        case 'dongganpuke':
          return 'poker'
        case 'donggan61':
        case 'amlhc':
        case 'twlhc':
        case 'hk6':
        case 'happy61':
          return 'lhc'
        default:
          return 'number'
      }
    },
    codeClass() {
      if (this.imgVisible) {
        if (this.codeType === 'dice') return 'diceImg'
        return ''
      }
      return this.codeType + this.number
    },
    zhongClass() {
      const arr = []
      if (['ball', 'b-ball', 'dd', 'lhc'].indexOf(this.codeType) !== -1) {
        arr.push('c')
      }
      return arr
    },
    imgVisible() {
      if (this.isType) {
        return true
      }
      const arr = [
        'azxy8',
        'jisukl10',
        'azxy8168',
        'jisukl10168',
        'gdklsf',
        'azxy5',
        'azxy5168',
        'txffc',
        'jisussc',
        'jisussc168',
        'dongganwuxing',
        'sg5d',
        'pcdd',
        'donggan61',
        'amlhc',
        'twlhc',
        'hk6',
        'happy61'
      ]
      return arr.indexOf(this.lotteryCode) === -1
    },
    imgSrc() {
      let src = `/opencode/${this.codeType}/`
      if (this.isType) {
        switch (this.value) {
          case '大':
            src += 'big.png'
            break
          case '小':
            src += 'small.png'
            break
          case '单':
            src += 'single.png'
            break
          case '双':
            src += 'double.png'
            break
        }
        return src
      }
      src += `${this.number}.png`
      return src
    },
    text() {
      if (this.display !== 'default') return ''
      const arr =
        ['azxy8',
          'jisukl10',
          'azxy8168',
          'jisukl10168',
          'gdklsf',
          'azxy5',
          'azxy5168',
          'txffc',
          'jisussc',
          'jisussc168',
          'dongganwuxing',
          'sg5d',
          'pcdd',
          'donggan61',
          'amlhc',
          'twlhc',
          'hk6',
          'happy61']
      if (arr.indexOf(this.lotteryCode) !== -1) {
        return this.number
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.lottery-number {
  display: inline-block;
  width: 28px;
  height: 28px;

  position: relative;
  font-family: Microsoft YaHei;
  font-size: 14px;
  font-weight: bold;
  color: white;

  text-align: center;
  line-height: 28px;

  &+.lottery-number {
    margin-left: 5px;
  }

  >.img-code {
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
  }

  &.big {
    width: 36px;
    height: 36px;
    font-size: 24px;
    line-height: 36px;

    // & + .big.lottery-number {
    //   margin-left: 10px;
    // }
  }

  .badge {
    position: absolute;
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    border: 1px solid #FA3E3E;
    border-radius: 50%;
    font-size: 12px;
    color: #FA3E3E;
    font-style: normal;
    line-height: 14px;
    text-align: center;
    right: -9px;
    top: -8px;
    z-index: 400;
  }

  .result-check {
    position: absolute;
    top: 0;
    left: 0;
    display: table;
    height: 110%;
    width: 110%;
    background: url('~@assets/pc/bjpk10num.png') no-repeat;
    background-position: 0 -424px;

    &.c {
      background-position: -50px -425px;
    }
  }

  @for $i from 1 through 20 {
    >.ball#{$i} {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 16px;

      @if $i < 19 {
        background-color: #07a5f7;
      }

      @else {
        background-color: #ff6161;
      }

      &.big {
        border-radius: 19px;
      }

      .result-check {
        background-position: -50px -425px;
      }
    }
  }

  @for $i from 0 through 9 {
    >.b-ball#{$i} {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      background-color: #07a5f7;

      &.big {
        border-radius: 19px;
      }

      .result-check {
        background-position: -50px -425px;
      }
    }
  }

  @for $i from 1 through 49 {
    >.lhc#{$i} {
      display: block;
      width: 100%;
      height: 100%;
      color: #222;
      line-height: 24px;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      border-radius: 16px;

      &.big {
        border-radius: 19px;
      }

      .result-check {
        background-position: -50px -425px;
      }
    }
  }

  >.lhc1,
  >.lhc2,
  >.lhc7,
  >.lhc8,
  >.lhc12,
  >.lhc13,
  >.lhc18,
  >.lhc19,
  >.lhc23,
  >.lhc24,
  >.lhc29,
  >.lhc30,
  >.lhc34,
  >.lhc35,
  >.lhc40,
  >.lhc45,
  >.lhc46 {
    background: url('~@assets/pc/lhc1.png') no-repeat;
    background-size: 100% 100%;
  }

  >.lhc3,
  >.lhc4,
  >.lhc9,
  >.lhc10,
  >.lhc14,
  >.lhc15,
  >.lhc20,
  >.lhc25,
  >.lhc26,
  >.lhc31,
  >.lhc36,
  >.lhc37,
  >.lhc41,
  >.lhc42,
  >.lhc47,
  >.lhc48 {
    background: url('~@assets/pc/lhc2.png') no-repeat;
    background-size: 100% 100%;
  }

  >.lhc5,
  >.lhc6,
  >.lhc11,
  >.lhc16,
  >.lhc17,
  >.lhc21,
  >.lhc22,
  >.lhc27,
  >.lhc28,
  >.lhc32,
  >.lhc33,
  >.lhc38,
  >.lhc39,
  >.lhc43,
  >.lhc44,
  >.lhc49 {
    background: url('~@assets/pc/lhc3.png') no-repeat;
    background-size: 100% 100%;
  }

  @for $i from 0 through 10 {
    >.dd#{$i} {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      // background-color: #ccc;
      background-color: #07a5f7;

      &.big {
        border-radius: 19px;
      }

      .result-check {
        background-position: -50px -425px;
      }
    }
  }
}

.lhc_icon {
  width: auto;
  display: flex;
  align-items: center;

  >b {
    font-weight: bold;
    margin-right: 5px;
    color: #888888;
    font-size: 20px;
  }

  >span {
    width: 28px !important;
  }
}

.lottery_icon_add {
  justify-content: center;

  >b {
    font-weight: bold;
    margin-right: 0 !important;
    color: #888888;
  }
}

.diceImg {
  width: 80% !important;
  height: 80% !important;
}
</style>
